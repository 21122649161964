<template>
  <div>
    <b-row v-if="cars.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="cars"
            :fields="fields"
            head-row-variant="secondary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(active)="data">
              <span v-if="data.item.active == 1"> Работает </span>
              <span v-else> Не работает </span>
            </template>
            <template #cell(car_drivers)="data">
              <div
                @dblclick="getDrivers(data)"
                class="text-primary"
                v-for="drivers in data.item.car_drivers"
                :key="drivers.id"
              >
                {{ drivers.first_name + " " + drivers.last_name }};
              </div>
            </template>
            <template #cell(history)="data">
              <span @dblclick="getHistory(data)" class="reference-field">
                (***)
              </span>
            </template>
            <template #cell(photo_controls)="data">
              <span @dblclick="getPhoto(data)" class="reference-field">
                (***)
              </span>
            </template>
            <template #cell(model)="data">
              <span>
                {{ data.item.car_brand + " " + data.item.model }}
              </span>
            </template>
            <template #cell(dop_options)="data">
              <span v-for="options in data.item.dop_options" :key="options.id">
                {{ options.name + ", " }}
              </span>
            </template>
            <template #cell(weight)="data">
              <div v-if="data.item.cargo_properties">
                {{ data.item.cargo_properties.carrying_capacity }}
              </div>
            </template>
            <template #cell(length)="data">
              <span v-if="data.item.cargo_properties">
                {{ data.item.cargo_properties.length }}
              </span>
            </template>
            <template #cell(height)="data">
              <span v-if="data.item.cargo_properties">
                {{ data.item.cargo_properties.height }}
              </span>
            </template>
            <template #cell(width)="data">
              <span v-if="data.item.cargo_properties">
                {{ data.item.cargo_properties.width }}
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
  props: ["cars"],
  data() {
    return {
      openCar: false,
      id: "",
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "model",
          label: "Модель",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "color",
          label: "Цвет",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "car_number",
          label: "Номер машины",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "year_of_issue",
          label: "Год выпуска",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "condition",
          label: "Состояние",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "body_type",
          label: "Тип кузова",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "class_car",
          label: "Класс авто",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "car_drivers",
          label: "Водители",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "dop_options",
          label: "Доп. параметры",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "history",
          label: "История",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "photo_controls",
          label: "Фотоконтроль",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    getDrivers(data) {
      this.$router.push({
        name: "driverInfo",
        query: { filter: data.item.id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + data.item.id,
        `/driver/driverInfo`,
        data.item.id,
      ]);
    },
    getHistory(data) {
      this.$router.push({
        name: "carChangeHistory",
        query: { car_id: data.item.id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "История " + data.item.id`/cars/changeHistory`,
        data.item.id,
      ]);
    },
    getPhoto(data) {
      this.$router.push({
        name: "photo-control/performer-assign/searchInfo",
        query: { filter: data.item.performer_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Фотоконтроль " + data.item.performer_id,
        `/taxi/photo-controlInfo`,
        data.item.performer_id,
      ]);
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.textContent.trim();
      cellSelect();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
